<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Invoices')+' '+$t('States')" description=""></block-head>
      <block-content>
        <row class="g-gs">
          <column sm="6" lg="3">
            <card class="nk-ecwg">
              <card-title-group :card-title="$t('This Week')"></card-title-group>
              <card-data :amount="states.this_week.invoices">
                <template v-slot:info>
                      <span class="change" :class="states.this_week.progress < 1 ? 'down':'up'">
                        <nio-icon :icon="states.this_week.progress < 1 ? 'ni-arrow-long-down' : 'ni-arrow-long-up'"></nio-icon>{{states.this_week.progress}}%</span>
                  <span> vs. {{$t('last week')}}</span>
                </template>
              </card-data>
            </card>
          </column>
          <column sm="6" lg="3">
            <card class="nk-ecwg">
              <card-title-group :card-title="$t('This Month')"></card-title-group>
              <card-data :amount="states.this_month.invoices">
                <template v-slot:info>
                      <span class="change" :class="states.this_month.progress < 1 ? 'down':'up'">
                        <nio-icon :icon="states.this_month.progress < 1 ? 'ni-arrow-long-down' : 'ni-arrow-long-up'"></nio-icon>{{states.this_month.progress}}%</span>
                  <span> vs. {{$t('last month')}}</span>
                </template>
              </card-data>
            </card>
          </column>
          <column sm="6" lg="3">
            <card class="nk-ecwg">
              <card-title-group :card-title="$t('Due')"></card-title-group>
              <card-data :amount="states.due.invoices">
                <template v-slot:info>
                  <span>{{ $t('All times') }}</span>
                </template>
              </card-data>
            </card>
          </column>
          <column sm="6" lg="3">
            <card class="nk-ecwg">
              <card-title-group :card-title="$t('Total')+' '+$t('Revenue')"></card-title-group>
              <card-data :amount="'&euro;'+states.revenue.total">
                <template v-slot:info>
                  <span>{{$t('All times')}}</span>
                </template>
              </card-data>
            </card>
          </column>
          <column default="12" md="6">
            <card no-padding full-height>
              <template v-slot:other>
                <card-inner>
                  <card-title-group :card-title="$t('Invoices by status')">
                    <card-tools>

                    </card-tools>
                  </card-title-group>
                </card-inner>
                <special-table class="is-loose">
                  <table-row is-heading>
                    <table-col><span>{{ $t('Status')}}</span></table-col>
                    <table-col class="text-center"><span>{{$t('Invoices')}}</span></table-col>
                    <table-col break-point="sm"><span>%</span></table-col>
                    <table-col class="text-right"><span>{{$t('Amount')}}</span></table-col>
                  </table-row>
                  <table-row v-for="inv_st in states.status_wise" :key="inv_st.status_id">
                    <table-col>
                      <span :style="{color: invoiceStatusRender(inv_st.status).color}">{{ invoiceStatusRender(inv_st.status).status_text }}</span>
                    </table-col>
                    <table-col class="text-center">{{inv_st.total}}</table-col>
                    <table-col class="text-center" break-point="sm">
                      <progressbar :color="invoiceStatusRender(inv_st.status).color" :progress="parseFloat(inv_st.percentage)" show-progress-amount alt bg="transparent" height="md"></progressbar>
                    </table-col>
                    <table-col class="text-right">&euro;{{ inv_st.amount }}</table-col>
                  </table-row>
                </special-table>
              </template>
            </card>
          </column>
          <column default="12" md="6">
            <card full-height>
              <ion-block-spinner v-if="spinners.revenue_chart"></ion-block-spinner>
              <card-title-group :card-title="$t('Revenue')" class="mb-3">
                <card-tools>
                  <drop-down title="" :trigger-btn-text="filters.revenue_chart" size="xs" trigger-btn-classes="dropdown-indicator btn-sm btn-outline-light btn-white">
                    <ul class="link-list-opt no-bdr">
                      <li v-for="yr in years" :key="yr" :class="{active: filters.revenue_chart === yr}">
                        <a href="javascript:;" v-on:click="filters.revenue_chart = yr"><span>{{ yr }}</span></a>
                      </li>
                    </ul>
                  </drop-down>
                </card-tools>
              </card-title-group>
              <div class="nk-sale-data-group align-center justify-between gy-3 gx-5">
                <div class="nk-sale-data">
                  <span class="amount">&euro;{{ states.revenue.chart.total.revenue}}</span>
                </div>
                <div class="nk-sale-data">
                  <span class="amount sm">{{ states.revenue.chart.total.invoices}} <small>{{ $t('Invoices')}}</small></span>
                </div>
              </div>
              <div class="nk-sales-ck large pt-4">
                <chartjs-line :colors="[]" :data-set="[states.revenue.chart.data_set]" :labels="states.revenue.chart.labels" chart-id="lead-revenue-chart"></chartjs-line>
              </div>
            </card>
          </column>
        </row>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {isPlatform} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {BlockContent, BlockHead, Column, MainBlock, Row} from "@core/layouts";
import {Card, CardData, CardInner, CardTitleGroup, CardTools} from "@core/components/cards";
import axios from "@/libs/axios";
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import {useStore} from "vuex";
import useCommon from "@core/comp-functions/common"
import { ChartjsLine, DropDown, Progressbar} from "@core/components";
import IonBlockSpinner from "@core/components/spinners/IonBlockSpinner";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    IonBlockSpinner,
    DropDown,
    Progressbar,
    CardInner,
    TableCol,
    TableRow,
    SpecialTable,
    CardTools,
    CardTitleGroup,
    Card,
    CardData,
    ChartjsLine,
    Column,
    Row,
    BlockHead,
    BlockContent,
    MainBlock,
  },
  setup() {

    const isDesktop = isPlatform('desktop')
    const {updateObject} = useCommon()
    let filters = reactive({
      revenue_chart: new Date().getFullYear(),
    })
    let spinners = reactive({
      revenue_chart: false,
    })
    let states = reactive({
      this_week: {
        invoices: 0,
        progress: 0,
      },
      this_month: {
        invoices: 0,
        progress: 0,
      },
      due: {
        invoices: 0,
      },
      revenue: {
        total:0,
        chart: {
          labels: [],
          data_set: {
            label: 'Revenue',
            borderColor: "#9d72ff",
            backgroundColor: ['rgba(157,114,255, .3)'],
            tension: 0.1,
            borderWidth: 2,
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: '#9d72ff',
            pointBorderWidth: 2,
            pointHoverRadius: 3,
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 3,
            fill: true,
            data: [],
          },
          total: {
            revenue: 0,
            invoices: 0,
          }
        },
      },
      status_wise: [],
    })

    const getStates = () => {
      axios.post('/api/admin/invoices/states',{year_filter: filters.revenue_chart})
          .then( (resp) => {
            if(!resp.data.success){
              alert(resp.data.message)
            }
            else{
              updateObject(states, resp.data.data)
            }
          })
          .catch( err => {
            console.log('Lead details ajax: '+err)
          })
      .then(()=>{
        for(let x in spinners){
          spinners[x] = false
        }
      })
    }
    onMounted(getStates)

    const store = useStore()
    const invoiceStatuses = store.state.general_data.invoice_statuses
    const invoiceStatusRender = (id) => {
      id = parseInt(id)
      for(let x in invoiceStatuses){
        if(parseInt(invoiceStatuses[x].id) === id){
          return invoiceStatuses[x]
        }
      }
      return {status_text: 'Undefined', color: '#333'}
    }

    let years = ref([])
    const currentYear = new Date().getFullYear()
    // 2021? because we started this in 2021
    let diff = currentYear - 2021
    diff = diff > 10 ? 10 : diff
    const startYear = currentYear - diff
    for(let i = currentYear; i >= startYear; i--){
      years.value.push(i)
    }

    watch(()=>filters.revenue_chart, ()=> {
      spinners.revenue_chart = true
      getStates()
    })

    return {
      filters,
      invoiceStatusRender,
      isDesktop,
      spinners,
      states,
      years,
    }
  }
});

</script>
